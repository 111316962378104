import { createContext, useContext } from 'react';
import { IHasOrderByCpfUsecase } from './domain/usecases/has_order_by_cpf_usecase';

const HasOrderByCpfContext = createContext<IHasOrderByCpfUsecase | undefined>(
  undefined,
);

export function useHasOrderByCpfUsecase() {
  const context = useContext(HasOrderByCpfContext);
  if (context === undefined) {
    throw new Error('IHasOrderByCpfUsecase not found');
  }
  return context;
}

export default HasOrderByCpfContext;
