import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig } from "axios";
import {
  HttpHeaders,
  HttpMethod,
  HttpResponse,
  IHttpClient,
} from "core/protocols/http_client";
import { IEncryptService } from "core/service/encrypt.service";

export class HttpClient implements IHttpClient {
  private instance: AxiosInstance;
  private encryptService: IEncryptService;

  constructor(args: { baseUrl: string, accessToken?: string, useEncryption?: boolean, encryptService: IEncryptService }) {
    this.encryptService = args.encryptService;
    const headers = new AxiosHeaders();
    headers.set("Content-Type", "application/json");

    if (args.accessToken) {
      headers.set("Authorization", `${args.accessToken}`);
    }

    if (args.useEncryption) {
      headers.set("Encrypt", "true");
    }

    if (process.env.REACT_APP_ENV === 'AUTOMATION') {
      headers.set("x-automated-test", "True");
    }

    this.instance = axios.create({
      baseURL: args.baseUrl,
      headers
    });
  }

  async request<T>(
    method: HttpMethod,
    url: string,
    data?: any,
    customHeaders?: HttpHeaders,
    encrypt = false,
  ): Promise<HttpResponse<T>> {
    const headers: any = {
      ...this.instance.defaults.headers.common,
      ...customHeaders,
    };

    if (customHeaders && customHeaders.Authorization) {
      headers.Authorization = customHeaders.Authorization;
    }

    const config: AxiosRequestConfig = {
      method,
      url,
      headers,
    };

    if (data) {
      if (encrypt && this.instance.defaults.headers.Encrypt === "true") {
        config.data = {
          data: this.encryptService.encrypt(data),
        };
      } else {
        config.data = data;
      }
    }

    return this.instance.request<T>(config);
  }

  async get<T>(
    url: string,
    customHeaders?: HttpHeaders
  ): Promise<HttpResponse<T>> {
    return this.request<T>("GET", url, undefined, customHeaders);
  }

  async post<T>(
    url: string,
    data?: any,
    customHeaders?: HttpHeaders,
    encrypt?: boolean,
  ): Promise<HttpResponse<T>> {
    return this.request<T>("POST", url, data, customHeaders, encrypt);
  }

  async put<T>(
    url: string,
    data?: any,
    customHeaders?: HttpHeaders,
    encrypt?: boolean,
  ): Promise<HttpResponse<T>> {
    return this.request<T>("PUT", url, data, customHeaders, encrypt);
  }

  async delete<T>(
    url: string,
    customHeaders?: HttpHeaders
  ): Promise<HttpResponse<T>> {
    return this.request<T>("DELETE", url, undefined, customHeaders);
  }
}
