import { IconArrowLeft } from "gosafe/atoms/icons";

import Button, { ButtonProps } from "./button";

type BackButtonProps = ButtonProps;

export default function BackButton({
  onClick,
  color,
  hasBorder = true,
  id="btn-back",
}: BackButtonProps) {
  return (
    <div>
      <Button
        id={id}
        backgroundColor="bg-transparent"
        onClick={onClick}
        hasBorder={hasBorder}
        showIconLeft={true}
        color={color}
        padding="px-[10px] py-[10px]"
        iconLeft={<IconArrowLeft />}
      />
    </div>
  );
}
