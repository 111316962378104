import cpfValidator from 'core/validators/cpf_validator';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useHasOrderByCpfUsecase } from '../has_order_by_cpf_context';
import { useState } from 'react';
import { CustomerEntity } from 'features/purchase/domain/entities/customer_entity';
import { useGetUserUsecase } from 'features/purchase/get_user_context';
import { useAuthentication } from 'features/authentication/authentication_bindings';

interface CPFCaptureType {
  cpf: string;
}

function useCPFCaptureController() {
  const navigate = useNavigate();
  const hasOrderByCpfUsecase = useHasOrderByCpfUsecase();
  const [requesting, setRequesting] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const { currentUser } = useAuthentication();
  const getUserUsecase = useGetUserUsecase();
  const [getUserLoading, setGetUserLoading] = useState(false);
  const [cpfIsFromAPI, setCpfIsfromAPI] = useState(false);

  const getCpf = async () => {
    setGetUserLoading(true);
    try {
      const user: CustomerEntity = await getUserUsecase.execute(
        currentUser?.uid ?? '',
      );
      if (user.cpf) {
        setCpfIsfromAPI(true);
        form.setFieldValue('cpf', user.cpf, true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setGetUserLoading(false);
    }
  };

  const form = useFormik<CPFCaptureType>({
    initialValues: {
      cpf: '',
    },
    validationSchema: Yup.object().shape({
      cpf: Yup.string()
        .required('O CPF é obrigatório!')
        .test('cpf', 'CPF inválido!', (value) => {
          const onlyNumbers = value.replace(/\D/g, '');
          return cpfValidator(onlyNumbers);
        }),
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      form.setTouched({
        cpf: true,
      });

      if (form.isValid) {
        const onlyNumbers = values.cpf.replace(/\D/g, '');
        setRequesting(true);
        setShowInfo(false);
        try {
          await hasOrderByCpfUsecase.execute(onlyNumbers);
          setRequesting(false);
          navigate('/email-check');
        } catch (error) {
          console.error(error);
          setShowInfo(true);
          setRequesting(false);
        }
      }
    },
  });

  function goToPurchase() {
    navigate(`/purchase?cpf=${form.values.cpf.replace(/\D/g, '')}`);
  }

  return {
    form,
    requesting,
    showInfo,
    getUserLoading,
    cpfIsFromAPI,
    getCpf,
    goToPurchase,
  };
}

export default useCPFCaptureController;
