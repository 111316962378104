import { compressToEncodedURIComponent } from "lz-string";
import { useCallback, useEffect, useState } from "react";
import { FooterSection } from "../../../components";
import { ExaLoading } from "../../../gosafe";
import { BasePage } from "../../../gosafe/templates";
import TestefoneHeaderThin from "../../authentication/presenter/components/testefone_header_thin";
import CustomerPageProvider from "../../authentication/presenter/sign_in_page_provider";
import Chatbot from "../../chatbot";
import { useGetShortUrlUseCase } from "../get_short_url_context";
import QrCodeView from "./components/qr_code_view";

function QrCodePage() {
  const [loading, setLoading] = useState(true);
  const getShortUrl = useGetShortUrlUseCase();
  const [shortUrl, setShortUrl] = useState("");

  const shortenUrl = useCallback(async () => {
    const encodedState = compressToEncodedURIComponent(
      JSON.stringify(history.state)
    );
    const url = `${location.origin}/purchase/${encodedState}`;
    const shortUrlResponse = await getShortUrl.execute(url);
    setShortUrl(shortUrlResponse.shortUrl);
  }, []);

  useEffect(() => {
    setLoading(true);
    shortenUrl().finally(() => setLoading(false));
  }, []);

  return (
    <CustomerPageProvider data-testid="CustomerPageProvider">
      <BasePage
        header={<TestefoneHeaderThin data-testid="TestefoneHeaderThin" />}
        data-testid="BasePage"
      >
        {loading && (
          <div className="flex flex-col gap-4 justify-center items-center h-[500px] text-primary">
            <ExaLoading />
          </div>
        )}
        {!loading && <QrCodeView url={shortUrl} />}
        <FooterSection />
        <Chatbot primary={false} />
      </BasePage>
    </CustomerPageProvider>
  );
}

export default QrCodePage;
