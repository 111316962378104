import { ISignIn } from 'core/authentication/sign_in';
import { UserInterface } from 'core/authentication/users_interface';
import { FirebaseApp } from 'firebase/app';
import {
  Auth,
  OAuthProvider,
  User,
  UserCredential,
  getAuth,
  signInWithPopup,
} from 'firebase/auth';
import * as Sentry from '@sentry/react';

class SignInWithAppleUsecase implements ISignIn {
  private app: FirebaseApp;

  constructor(app: FirebaseApp) {
    this.app = app;
  }

  async execute(): Promise<UserInterface> {
    try {
      const auth: Auth = getAuth(this.app);
      const provider: OAuthProvider = new OAuthProvider('apple.com');
      const result: UserCredential = await signInWithPopup(auth, provider);
      const firebaseUser: User = result.user;
      const mappedUser: UserInterface = {
        uid: firebaseUser.uid || '',
        email: firebaseUser.email || '',
        emailVerified: firebaseUser.emailVerified || false,
        displayName: firebaseUser.displayName || '',
        isAnonymous: firebaseUser.isAnonymous || false,
        photoURL: firebaseUser.photoURL || '',
        providerData: firebaseUser.providerData
          ? firebaseUser.providerData.map((provider) => ({
              uid: provider.uid || '',
              displayName: provider.displayName || '',
              photoURL: provider.photoURL || '',
              email: provider.email || '',
              providerId: provider.providerId || '',
              phoneNumber: '',
            }))
          : [],
        stsTokenManager: {
          refreshToken: firebaseUser.refreshToken || '',
          accessToken: '',
          expirationTime: 0,
        },
        createdAt: '',
        lastLoginAt: '',
        apiKey: '',
        appName: '',
        authMethod: 'apple',
      };
      return mappedUser;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          'feature.authentication': 'SignInWithAppleUsecase',
        },
      });
      console.error('Error signing in with Apple account:', error);
      throw error;
    }
  }
}

export default SignInWithAppleUsecase;
