import { useState } from 'react';

import { User } from 'firebase/auth';

export interface IAuthState {
  isLogged: boolean;
  isLoading?: boolean;
  currentUser?: User | null;
  setCurrentUser: (currentUser: any) => void;
  setIsLoading: (isLoading: boolean) => void;
  accessToken?: string | null;
  setAccessToken: (accessToken: string) => void;
  authMethod?: string | null;
  setAuthMethod: (authMethod: string) => void;
}

interface AuthStateProps {
  loading?: boolean;
}

const AuthState = ({ loading = false }: AuthStateProps): IAuthState => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [authMethod, setAuthMethod] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(loading);
  const isLogged = currentUser !== null;

  return {
    isLogged,
    isLoading,
    currentUser,
    setCurrentUser,
    setIsLoading,
    accessToken,
    setAccessToken,
    authMethod,
    setAuthMethod,
  };
};

export default AuthState;
