import {
  ChangeEventHandler,
  FocusEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
} from "react";
import InputMask from "react-input-mask";

interface TextFieldProps {
  id?: string;
  textLabel?: string;
  showHelper?: boolean;
  textHelper?: string | ReactNode;
  hintText?: string;
  showErros?: boolean;
  onClick?: MouseEventHandler;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onInput?: FormEventHandler<HTMLInputElement>;
  name: string;
  value?: string | number | readonly string[] | undefined;
  errorMessage?: string;
  readOnly?:boolean;
  disabled?: boolean;
  mask?: string | (string | RegExp)[];
  maskChar?: string | null | undefined;
}

function TextField({
  id,
  textLabel = "Label",
  hintText = "Label",
  showHelper = false,
  textHelper = "Heper text",
  onChange,
  onBlur,
  onKeyUp,
  onInput,
  name,
  readOnly=false,
  disabled = false,
  showErros = false,
  mask = "",
  value,
  errorMessage,
  maskChar = null }: TextFieldProps) {

  const className = [
    "relative",
    "w-full",
    "px-4",
    "py-4",
    "border-2",
    "rounded-xl",
    "focus:outline-none",
  ];

  if (disabled) {
    className.push(
      "bg-neutral-80 border-neutral-70 placeholder:text-neutral-60"
    );
  } else if (showErros && errorMessage) {
    className.push("border-error/4 hover:border-error/2 focus:border-error");
  } else {
    className.push(
      "border-neutral-80 hover:border-neutral-70 focus:border-neutral-60"
    );
  }

  return (
    <div data-testid={`TextField-${name}`}>
      <label
        className="ml-4 font-serif text-xs font-bold text-neutral-30"
        htmlFor={name}
      >
        {textLabel}
      </label>

      <InputMask
        id={id}
        name={name}
        mask={mask}
        maskChar={maskChar}
        disabled={disabled}
        value={value}
        onKeyUp={onKeyUp}
        onInput={onInput}
        className={className.join(" ")}
        placeholder={hintText}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={readOnly}
        data-testid={`InputMask-${name}`}
      />

      {showErros && errorMessage && (
        <div
          data-testid={`TextField-${name}-error`}
          className="pl-4 text-xs text-error "
        >
          {errorMessage}
        </div>
      )}

      {showHelper && (
        <span
          data-testid={`TextField-${name}-helper`}
          className="ml-4 text-xs"
        >
          {textHelper}
        </span>
      )}
    </div>
  );
}

export default TextField;
