import HasOrderByCpfContext from './has_order_by_cpf_context';
import { HasOrderByCpfUsecaseImpl } from './domain/usecases/has_order_by_cpf_usecase';
import CPFCapturePage from './presenter/cpf_capture_page';
import { useHttpSalesClient } from 'bindings/http_sales_binding';
import { GetUserContext } from 'features/purchase/get_user_context';
import GetUserUsecaseImpl from 'features/purchase/domain/usecases/get_user_usecase';

function CPFCapture() {
  const httpClient = useHttpSalesClient();

  return (
    <GetUserContext.Provider value={new GetUserUsecaseImpl(httpClient)}>
      <HasOrderByCpfContext.Provider
        value={new HasOrderByCpfUsecaseImpl(httpClient)}
      >
        <CPFCapturePage />
      </HasOrderByCpfContext.Provider>
    </GetUserContext.Provider>
  );
}

export default CPFCapture;
