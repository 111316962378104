import { useState } from "react";

import { PhoneEntity } from "features/brand_and_model_search";

import ImeiCheckEntity from "../domain/entities/imei_check_entity";
import ImeiCheckResultEntity from "../domain/entities/imei_check_result_entity";
import { useImeiCheckUsecase } from "../imei_check_context";

export interface ImeiCheckProps {
  phone: PhoneEntity | undefined;
  onTap?: (imeiCheckResultEntity: ImeiCheckResultEntity) => void;
}

export function useImeiCheckController({ phone, onTap }: ImeiCheckProps) {
  const [imeiLoading, setImeiLoading] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [optionClicked, setOptionClicked] = useState<number|null>(null);

  const imeiCheckUseCase = useImeiCheckUsecase();

  async function check(query: string): Promise<boolean> {
    if (phone === undefined) {
      return false;
    }

    setImeiLoading(true);

    return await imeiCheckUseCase
      .execute(
        new ImeiCheckEntity(
          query,
          phone.brand?.name ?? '',
          phone.identifiers ?? [],
          phone.name ?? '',
        )
      )
      .then((result: ImeiCheckResultEntity) => {
        if (onTap) {
          onTap(result);
        }
        return result.isValid;
      })
      .catch(error => {
        return false;
      })
      .finally(() => {
        setImeiLoading(false);
      });
  }

  function openImeiDefinitionInfo() {
    setOpenInfo(true);
    setOptionClicked(1);
  }

  function openLocationImeiInfo() {
    setOpenInfo(true);
    setOptionClicked(0);
  }

  function closeInfo() {
    setOpenInfo(false);
    setOptionClicked(null);
  }

  return {
    imeiLoading,
    check,
    openLocationImeiInfo,
    openImeiDefinitionInfo,
    openInfo,
    closeInfo,
    optionClicked
  };
}
