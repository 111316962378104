import { IHttpClient } from 'core/protocols/http_client';
import * as Sentry from '@sentry/react';

export interface IHasOrderByCpfUsecase {
  execute(cpf: string): Promise<boolean>;
}

export class HasOrderByCpfUsecaseImpl implements IHasOrderByCpfUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async execute(customerId: string): Promise<boolean> {
    const url = '/orders/platform';
    try {
      await this.httpClient.put(url, {
        customerId,
      });
      return true;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          'feature.cpf_capture': 'HasOrderByCpfUsecaseImpl',
          'api.endpoint': url,
        },
      });
      throw error;
    }
  }
}
