import { ProductModel } from "models";
import { CustomerEntity } from "./customer_entity";
import { PhoneEntity } from "features/brand_and_model_search";

class PurchaseFromAppEntity {
  imei: string;
  state: string;
  auth_uid: string;
  invoice_date: string;
  product: ProductModel;
  customer: CustomerEntity;
  auth_token: string;
  device_info: PhoneEntity;
  constructor(props: {
    imei: string;
    state: string;
    auth_uid: string;
    invoice_date: string;
    product: ProductModel;
    customer: CustomerEntity;
    auth_token: string;
    device_info: PhoneEntity;
  }) {
    this.customer = props.customer;
    this.imei = props.imei;
    this.state = props.state;
    this.auth_uid = props.auth_uid;
    this.invoice_date = props.invoice_date;
    this.product = props.product;
    this.auth_token = props.auth_token;
    this.device_info = props.device_info;
  }
}

export default PurchaseFromAppEntity;