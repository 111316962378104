import { IconArrowRight } from 'gosafe/atoms/icons';
import { DropdownField, DropdownFieldItem } from 'gosafe/molecules';
import { SecondaryButton } from 'gosafe/molecules/buttons';

import { InfinityLoading, TextField } from 'gosafe';
import CheckboxField from 'gosafe/molecules/checkbox_field';
import { useGetBrandsByNameUsecase } from '../get_brands_by_name_context';
import { useGetModelsByBrandAndNameUsecase } from '../get_models_by_brand_and_name_context';
import { useUpdateLeadUsecase } from '../send_lead_context';
import {
    BrandAndModelProps,
    useBrandAndModelController
} from './brand_and_model_controller';

function BrandAndModel(props: BrandAndModelProps) {
    const {
        form,
        brand,
        brands,
        setBrand,
        phoneModel,
        phoneModels,
        brandLoading,
        phoneModelLoading,
        setPhoneModel,
        sentLeadLoading
    } = useBrandAndModelController(
        useGetBrandsByNameUsecase(),
        useGetModelsByBrandAndNameUsecase(),
        useUpdateLeadUsecase(),
        props
    );

    return (
        <form
            onSubmit={(e) => {
                if (phoneModel) {
                    form.handleSubmit(e);
                }
            }}
            data-testid="BrandAndModel"
        >
            <DropdownField
                id="brand"
                name="brand"
                textLabel="Marca"
                hintText="Digite ou selecione a marca"
                value={brand}
                loading={brandLoading}
                items={brands.map((brand) => {
                    return new DropdownFieldItem(brand.id, brand.name ?? '');
                })}
                onChanged={(value) => {
                    setBrand(value);
                    form.setFieldValue('brand', value?.label);
                }}
            />
            <div className="h-5" />
            <DropdownField
                id="model"
                name="model"
                textLabel="Modelo"
                hintText="Digite ou selecione o modelo"
                items={phoneModels.map((phoneModel) => {
                    return new DropdownFieldItem(
                        phoneModel.id,
                        phoneModel.name ?? ''
                    );
                })}
                onChanged={(value) => {
                    setPhoneModel(value);
                    form.setFieldValue('model', value?.label);
                }}
                value={phoneModel}
                loading={phoneModelLoading}
                disabled={brand === undefined}
            />
            <div className="h-5" />
            <TextField
                name="email"
                textLabel="E-mail"
                hintText="Digite seu e-mail"
                value={form.values.email}
                onChange={form.handleChange}
                showErros={
                    form.touched &&
                    form.touched.email &&
                    form.errors.email !== undefined &&
                    form.errors.email !== ''
                }
                errorMessage={form.errors.email}
            />
            <div className="h-1" />
            <CheckboxField
                id="opt_in"
                showLabel
                textLabel="Aceito receber conteúdos sobre segurança e ofertas por e-mail"
                value={form.values.opt_in}
                onChange={(value) => {
                    form.setFieldValue('opt_in', value);
                }}
            />
            <div className="h-5" />
            <SecondaryButton
                id="form-btn-cote-agora"
                infinity={true}
                enabled={brand !== undefined && phoneModel !== undefined}
                type={
                    brand !== undefined && phoneModel !== undefined
                        ? 'submit'
                        : 'button'
                }
                text={sentLeadLoading ? '' : 'Cote agora'}
                iconRight={
                    sentLeadLoading ? (
                        <InfinityLoading
                            width={24}
                            strokeColor="text-primary"
                        />
                    ) : (
                        <IconArrowRight />
                    )
                }
                showIconRight={true}
            />
        </form>
    );
}

export default BrandAndModel;
