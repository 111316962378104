import * as Sentry from '@sentry/react';
import { useHttpSalesClient } from 'bindings/http_sales_binding';
import { getAuth } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import { decompressFromEncodedURIComponent } from 'lz-string';
import { PlanModel, ProductModel } from 'models';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFirebase } from '../../../bindings/firebase_binding';
import { useHttpClient } from '../../../bindings/http_binding';
import { useAuthentication } from '../../authentication/authentication_bindings';
import { useCheckDetachedOrderUsecase } from '../check_detached_order_context';
import { useCreateUserUsecase } from '../create_user_context';
import { CustomerEntity, Email } from '../domain/entities/customer_entity';
import PolicyEntity from '../domain/entities/order_entity';
import PurchaseFromAppEntity from '../domain/entities/purchase_from_app_entity';
import { CreateUserInput } from '../domain/usecases/create_user_usecase';
import { useGetLastPolicyContext } from '../get_last_policy_context';
import { useGetUserUsecase } from '../get_user_context';
import { PurchaseActionType, PurchaseState } from './purchase_state';
import { usePurchaseState } from './purchase_state_context';
import { BrandEntity, PhoneEntity } from 'features/brand_and_model_search';

export function usePurchasePageController() {
  const app = useFirebase();
  const auth = getAuth(app);
  const [searchParams] = useSearchParams();
  const getUserUsecase = useGetUserUsecase();
  const getLastPolicyUseCase = useGetLastPolicyContext();
  const createUser = useCreateUserUsecase();
  const checkDetachedOrder = useCheckDetachedOrderUsecase();

  const { currentUser, isLogged, authMethod, setAccessToken } =
    useAuthentication();
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(
    searchParams.get('info') === 'dialog',
  );
  const { purchase, dispatch } = usePurchaseState();
  const navigate = useNavigate();
  const httpClient = useHttpClient();
  const httpSalesClient = useHttpSalesClient();

  let purchaseStateFromApp: PurchaseFromAppEntity | null = null;
  const appHRef = window.location.href;
  setAppState();
  function setAppState(): void {
    const { appState } = useParams();

    if (appState) {
      const decompressedAppState = decompressFromEncodedURIComponent(appState);
      history.replaceState(
        JSON.parse(decompressedAppState),
        '',
        `${window.location.origin}/purchase`,
      );
      const state = history.state.usr?.purchase as PurchaseState;
      dispatch({
        ...state,
        phone: state.phone,
        phoneInfo: state.phoneInfo,
        product: state.product,
        type: 'SET_PHONE',
      });
    }
  }

  useEffect(() => {
    navigate('/purchase', { state: { purchase } });
  }, [purchase]);

  const dataUrl = (href: string): boolean => {
    const urlString: string = href;
    const url: URL = new URL(urlString);
    const param: string | null = url.searchParams.get('data');
    if (param === null) {
      return false;
    }

    const token = param;
    const decoded: any = jwtDecode(token ?? '');
    purchaseStateFromApp = new PurchaseFromAppEntity({
      imei: decoded.imei,
      auth_uid: decoded.auth_uid,
      invoice_date: decoded.invoice_date,
      product: new ProductModel(
        decoded.product.amount,
        0,
        decoded.product.provider_id,
        decoded.product.transaction_id,
        0,
        new PlanModel(
          decoded.product.plan_code,
          decoded.product.id,
          decoded.product.name,
          '',
        ),
        'iphone',
      ),
      state: decoded.state,
      customer: decoded.customer,
      auth_token: decoded.auth_token,
      device_info: new PhoneEntity({
        brand: new BrandEntity(undefined, decoded.device_info.brand),
        name: decoded.device_info.name,
        storage: decoded.device_info.storage,
      }),
    });
    return true;
  };

  const getUser = async (uid: string): Promise<CustomerEntity> => {
    try {
      const user = await getUserUsecase.execute(uid);
      return user;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const getLastPolicy = async (): Promise<PolicyEntity | undefined> => {
    try {
      const policy: PolicyEntity = await getLastPolicyUseCase.execute();
      return policy;
    } catch (_error) {
      return undefined;
    }
  };

  const hasInvalidEmail = (emails: Email[]) => {
    return emails.some((email) => !email.validated);
  };

  const fetchData = async () => {
    setLoading(true);
    const isFromApp: boolean = dataUrl(appHRef);
    if (isFromApp !== false) {
      setAccessToken(purchaseStateFromApp!.auth_token);
      dispatch({
        type: purchaseStateFromApp!.state as PurchaseActionType,
        customer: purchaseStateFromApp!.customer,
        product: purchaseStateFromApp!.product,
        imei: purchaseStateFromApp!.imei,
        phone: purchaseStateFromApp!.device_info,
      });
      setLoading(false);
      return;
    }

    if (!isLogged) {
      setLoading(false);
      return;
    }
    try {
      const user: CustomerEntity = await getUser(currentUser!.uid);
      const cpfFromUrlParam = searchParams.get('cpf');
      const cpf = user.cpf ?? cpfFromUrlParam;
      if (!cpf) {
        navigate('/cpf-capture');
        return;
      }
      if (!hasInvalidEmail(user.emails ?? [])) {
        const hasDetachedOrder = await checkDetachedOrder.execute(
          cpf.replaceAll('.', '').replace('-', ''),
        );
        const updatedUser = {
          ...user,
          cpf: cpfFromUrlParam ? cpfFromUrlParam : user.cpf,
        };
        if (!hasDetachedOrder) {
          const lastPolicy = await getLastPolicy();
          if (!lastPolicy) {
            dispatch({ type: 'LOAD_CUSTOMER', customer: updatedUser });
            return;
          }
          if (lastPolicy.state === 'suspended') {
            dispatch({
              type: 'SET_TICKET_INACTIVE',
              customer: updatedUser,
              lastOrder: lastPolicy,
            });
            return;
          }
          if (lastPolicy.state === 'active') {
            dispatch({
              type: 'SET_TICKET_ENABLED',
              customer: updatedUser,
              lastOrder: lastPolicy,
            });
            return;
          }
          if (lastPolicy.state === 'payment_pending') {
            dispatch({ type: 'SET_PAYMENT_SUCCESS', customer: updatedUser });
            return;
          }
          dispatch({ type: 'LOAD_CUSTOMER', customer: updatedUser });
        }
        navigate('/cpf-capture');
      } else {
        navigate('/email-check');
      }
    } catch (_error) {
      await createUser
        .execute(
          new CreateUserInput({
            authMethod: authMethod ?? '',
            authUid: currentUser?.uid ?? '',
            email: currentUser?.email ?? '',
          }),
        )
        .then(() => {
          navigate('/cpf-capture');
        })
        .catch((e) => {
          Sentry.captureException(e, {
            tags: {
              'feature.purchase': 'PurchasePageController',
              component: 'usePurchasePageController',
            },
          });
        });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isLogged]);

  const currentStep = purchase.currentStep;

  return {
    purchase,
    loading,
    currentStep,
    dispatch,
    httpClient,
    httpSalesClient,
    auth,
    navigate,
    fetchData,
    isLogged,
    showDialog,
    setShowDialog,
    email: currentUser?.email,
  };
}
