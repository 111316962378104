import { useEffect, useState } from 'react';

import { PhoneEntity } from 'features/brand_and_model_search';
import {
    GetProductInput,
    IGetProductsUsecase
} from 'features/purchase/domain/usecases/get_products_usecase';
import { CardWrapper, Divider, ListTile, Radio, Text } from 'gosafe/atoms';
import { IconArrowRight } from 'gosafe/atoms/icons';
import { ExaLoading } from 'gosafe/molecules';
import { SecondaryButton } from 'gosafe/molecules/buttons';
import { ProductModel } from 'models';

interface ProductListProps {
    product: ProductModel | undefined;
    setProduct: (product: ProductModel) => void;
    getProducts: IGetProductsUsecase;
    phone?: PhoneEntity | undefined;
    onTap: (product: ProductModel) => void;
    onChange?: (product: ProductModel) => void;
}

function ProductList({
    product,
    setProduct,
    getProducts,
    phone,
    onTap
}: ProductListProps) {
    const [products, setProducts] = useState<ProductModel[] | undefined>(
        undefined
    );
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!products && phone) {
            setLoading(true);
            const input = new GetProductInput(
                phone.brand?.name ?? '',
                phone.name ?? '',
                phone.storage ?? ''
            );

            getProducts
                .execute(input)
                .then((products) => {
                    setProducts(products);
                })
                .catch(() => {
                    setProducts([]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    return (
        <div>
            {loading && (
                <div className="flex flex-col items-center gap-4 py-4">
                    <ExaLoading />
                    <Text fontWeight="font-medium" color="text-neutral-10">
                        Carregando produtos...
                    </Text>
                </div>
            )}
            {products && products.length > 0 && (
                <div data-testid="ProductList" className="flex flex-col gap-4">
                    {products.map((item, index) => {
                        return (
                            <CardWrapper
                                key={index}
                                selected={
                                    product !== undefined &&
                                    product.providerId === item.providerId
                                }
                                onTap={() => {
                                    setProduct(item);
                                }}
                            >
                                <div>
                                    <ListTile
                                        id={`ProductList-ListTile-${index}`}
                                        crossAxisAlignment="items-center"
                                        padding="px-6 py-2"
                                        leading={
                                            <Radio
                                                key={item.plan.id}
                                                value={
                                                    product !== undefined &&
                                                    product.plan.id ===
                                                        item.plan.id
                                                }
                                            />
                                        }
                                    >
                                        <div>
                                            <Text
                                                fontFamily="font-serif"
                                                fontSize="text-base"
                                                fontWeight="font-bold"
                                                color="text-neutral-0"
                                            >
                                                {item.price
                                                    ? new Intl.NumberFormat(
                                                          'pt-BR',
                                                          {
                                                              style: 'currency',
                                                              currency: 'BRL'
                                                          }
                                                      ).format(item.price)
                                                    : 'R$ 0,00'}{' '}
                                                ao mês
                                            </Text>
                                        </div>
                                        <div>
                                            <Text
                                                fontFamily="font-serif"
                                                fontSize="text-sm"
                                                color="text-neutral-0"
                                            >
                                                {item.plan.name}
                                            </Text>
                                        </div>
                                    </ListTile>
                                </div>
                            </CardWrapper>
                        );
                    })}
                    <Divider />
                    <SecondaryButton
                        id="btn-product-list-continue"
                        data-testid="ProductList-continue"
                        text="Continuar"
                        enabled={product !== undefined}
                        showIconRight={true}
                        onClick={() => {
                            onTap(product!);
                        }}
                        iconRight={<IconArrowRight />}
                    />
                </div>
            )}
        </div>
    );
}

export default ProductList;
